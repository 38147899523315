import ReportsTopbarTabs from '../components/ReportsTopbarTabs.vue'
import { MODULE_NAME } from '../reports.module'
const DefaultLayout = () =>
  import(
    /* webpackChunkName: "DefaultLayout" */
    '@/app/util-modules/ui/layouts/DefaultLayout.vue'
  )

export default [
  {
    path: '/workspaces/:workspaceId/reports',
    name: `${MODULE_NAME}.index`,
    meta: {
      layout: DefaultLayout,
      feature: MODULE_NAME,
      hiddenElements: ['areas', 'tags'],
    },
    components: {
      'topbar-left': ReportsTopbarTabs,
      default: () => import('@/app/modules/reports/pages/ReportsPage.vue'),
    },
  },
]
