<template>
  <tabs-topbar
    :tabs="tabs"
    data-id-prefix="reports_topbar_tabs"
    :modules="[]"
  />
</template>

<script>
import TabsTopbar from '@/app/util-modules/ui/tabs-topbar/TabsTopbar'
import { MODULE_NAME } from '../reports.module'
export default {
  name: 'ReportsTopbarTabs',
  components: {
    TabsTopbar,
  },
  data() {
    return {
      tabs: [
        {
          id: 'reports',
          label: () => this.$t('modules.reports.label'),
          route: { name: `${MODULE_NAME}.index` },
        },
      ],
    }
  },
}
</script>
