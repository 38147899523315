import { AxiosPromise } from 'axios'
import axios from '@/utils/vendors/axios'

import { ReportsMetabaseDashboardEmbed } from '../models/reports.model'

export default {
  getMetabaseReportsDashboardEmbed({
    workspaceId,
    userId,
    dashboardId,
  }: {
    workspaceId: RecordId
    userId: RecordId
    dashboardId: string
  }): AxiosPromise<{ iframe_url: string }> {
    return axios.request(
      new ReportsMetabaseDashboardEmbed({ workspaceId, userId, dashboardId })
    )
  },
}
