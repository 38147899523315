








import {
  defineComponent,
  ref,
  onMounted,
  onBeforeUnmount,
} from '@vue/composition-api'
import store from '@/store'
import BaseLoading from '@/app/util-modules/ui/loading/BaseLoading.vue'
import api from '../network/reports.api'

export default defineComponent({
  name: 'ReportsPage',
  components: {
    BaseLoading,
  },

  setup() {
    const isLoading = ref(true)
    const iframeUrl = ref('')

    const iframeContainer = ref<HTMLInputElement | null>(null)

    const iframeOnLoad = () => {
      isLoading.value = false
    }

    const createIframe = () => {
      const iframe = document.createElement('iframe')
      iframe.src = iframeUrl.value
      iframe.width = '100%'
      iframe.height = '1000'

      const container = iframeContainer.value
      if (container) {
        container.appendChild(iframe)
      }

      iframe.addEventListener('load', iframeOnLoad)
    }

    const fetchIframe = async () => {
      try {
        const { data: response } = await api.getMetabaseReportsDashboardEmbed({
          workspaceId: store.state.workspace.workspace.id,
          userId: store.state.user.user.id,
          dashboardId: process.env.VUE_APP_METABASE_DASHBOARD_ID as string,
        })
        iframeUrl.value = response.iframe_url
        createIframe()
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }

    onMounted(() => {
      fetchIframe()
    })

    onBeforeUnmount(() => {
      const iframe = iframeContainer.value?.querySelector('iframe')
      if (iframe) {
        iframe.removeEventListener('load', iframeOnLoad)
      }
    })

    return {
      isLoading,
      iframeUrl,
      iframeContainer,

      fetchIframe,
    }
  },
})
