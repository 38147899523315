import { REPORTS_ENDPOINT_METABASE_DASHBOARD_EMBED } from '../network/reports.endpoints'
import BaseRequest from '@/utils/api/base-request'

export class ReportsMetabaseDashboardEmbed extends BaseRequest {
  constructor({
    workspaceId,
    userId,
    dashboardId,
  }: {
    workspaceId: RecordId
    userId: RecordId
    dashboardId: string
  }) {
    super()
    super.url = REPORTS_ENDPOINT_METABASE_DASHBOARD_EMBED(
      workspaceId,
      userId,
      dashboardId
    )
  }
}
