import { RouteConfig } from 'vue-router'
import router from '@/router'

let isInstalled = false

// module name must match feature hint from backend API response
export const MODULE_NAME = 'Report'

export const STATE_RESET_MUTATION_NAME = `resetState`

export function install(): Promise<void> {
  const routeInstall = import(
    /* webpackChunkName: 'ReportsModule' */ './routes/reports.routes'
  ).then(exports.addRoutes)

  return Promise.all([routeInstall]).then(() => {
    isInstalled = true
  })
}

export function uninstall() {
  if (!isInstalled) {
    return
  }

  isInstalled = false
}

export function addRoutes(localRoutes: { default: Array<RouteConfig> }) {
  for (const route of localRoutes.default) {
    router.addRoute(route)
  }
}
